import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router"
import cn from "classnames"


import "./Header.scss"
import logo from "assets/logo.png"
import {ConnectKitButton} from "connectkit";

export const Header = (): JSX.Element => {

  return (
    <header
      className={cn("header", {})}
    >
      <img src={logo} alt='logo'/>
      <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
    </header>
  )
}
