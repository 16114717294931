import "animate.css"
import "./styles/global.scss"
import "./App.css"
import {Pool} from "./components/Pool";
import {Header} from "./components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useEffect} from "react";

function App() {

  const pools = [
    {
      days: '7',
      percent: '1.2%',
      limits: '10-100'
    },
    {
      days: '14',
      percent: '1.3%',
      limits: '70-150'
    },
    {
      days: '30',
      percent: '1.4%',
      limits: '100-175'
    },
    {
      days: '60',
      percent: '1.6%',
      limits: '150-200'
    },
  ]

  return (
    <main>
      <div className='space' />
      <Header/>
      <div className='main-content-wrapper'>
        <h1>Pluton Finance</h1>
        <p>
          We're thrilled to announce that Pluton Finance has officially entered its First phase! Your valuable feedback and collaboration are instrumental in helping us create our new platform. Thank you for your continued support! For any assistance, please reach out to us at <a href="mailto:support@pluton.finance">support@pluton.finance</a>.
        </p>
        <div className='main-content'>
          {pools.map((i, index) => (
            <Pool key={index} days={i.days} percents={i.percent} limits={i.limits} comingSoon={false}/>
          ))}
        </div>
      </div>
      <ToastContainer />
    </main>
  );
}

export default App;
